<template>
  <div class="school">
  
  </div>
</template>

<script>
export default {
  name: "school",
  props: {
    msg: String,
  },
  mounted() {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
