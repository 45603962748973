<template>
  <div>
    <div class="login-logo"></div>

    <div class="login-out w820 pr">
      <div class="login-out-pa pa"></div>
      <div class="login-in-pa pa"></div>
      <div class="login-in">
        <div class="login-inner">
          <div class="login-reg-student clearfix">
            <div class="login-reg-main fl" v-if="!loginState">
              <h1>学生注册</h1>
              <form>
                <input type="hidden" name="template" value="student" />
                <input type="hidden" name="forward" value="" />
                <div class="login-input pr">
                  <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="邮箱或手机"
                    v-model="form.username"
                  />
                  <label class="login-error pa"></label>
                </div>
                <div class="login-input pr">
                  <input
                    type="text"
                    class="showpwd"
                    name="password"
                    placeholder="密码6-20位"
                    v-model="form.password"
                  />
                  <label class="login-error pa"></label>
                </div>
                <div class="login-input pr">
                  <input
                    type="text"
                    class="showpwd"
                    name="repassword"
                    placeholder="确认密码"
                    v-model="form.repassword"
                  />
                  <label class="login-error pa"></label>
                </div>
                <div class="login-input pr">
                  <input
                    type="text"
                    class="showpwd"
                    name="name"
                    placeholder="姓名"
                    v-model="form.name"
                  />
                  <label class="login-error pa"></label>
                </div>

                <div class="login-input pr">
                  <el-select v-model="form.category" placeholder="请选择组别">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>

                <div class="login-input pr" @click="schoolVisible = true">
                  <input
                    type="text"
                    class="school-selector"
                    name="school"
                    autocomplete="off"
                    placeholder="学校"
                    v-model="form.school"
                  />
                  <label class="login-error pa"></label>
                </div>

                <div class="login-input pr">
                    <input
                    type="text"
                    class="showpwd"
                    name="invitation"
                    placeholder="请输入邀请码"
                    v-model="form.invitation"
                  />
                 
                  <label class="login-error pa"></label>
                </div>
                <div class="login-input pr">
                  <input
                    type="text"
                    name="code"
                    class="w80"
                    placeholder="验证码"
                    v-model="form.code"
                  />
                  <a
                    class="login-btn login-vcode-btn pa"
                    @click="sendMessage"
                    >{{ smsButton }}</a
                  >
                </div>
                <div class="login-reg-agreement">
                  <!-- 注册账号，即表示你同意<a onclick="show_protocol();"
                    >《社区注册协议》</a
                  > -->
                </div>
                <div class="login-reg-register">
                  <a class="login-btn login-reg-btn" @click="register()"
                    >注册</a
                  >
                </div>
              </form>
              <div class="fr">
                <div class="go-login" @click="loginState = true">登录</div>
              </div>
            </div>
            <div class="login-reg-main fl" v-if="loginState">
              <h1>学生登录</h1>
              <form
                style="margin-top: 70px"
                action="http://nxcodenet-10fd8f-1301115590.ap-shanghai.service.tcloudbase.com/creatUser"
                method="post"
              >
                <input type="hidden" name="template" value="student" />
                <input type="hidden" name="forward" value="" />
                <div class="login-input pr">
                  <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="邮箱或手机"
                    v-model="form.username"
                  />
                  <label class="login-error pa"></label>
                </div>
                <div class="login-input pr">
                  <input
                    type="text"
                    class="showpwd"
                    name="password"
                    placeholder="密码6-20位"
                    v-model="form.password"
                  />
                  <label class="login-error pa"></label>
                </div>

                <div class="login-reg-register" style="margin-top: 30px">
                  <a class="login-btn login-reg-btn" @click="login()">登录</a>
                </div>
              </form>
              <div class="fr">
                <!-- <img
                  src="./会员注册_3D One官网www.i3done.com_files/icon_14.gif"
                  class="align_m"
                /> -->
                <div class="go-login" @click="loginState = false">注册</div>
              </div>
            </div>
            <div class="login-reg-other fr">
              <div class="atom">
                <div class="electron"></div>
                <div class="electron-alpha"></div>
                <div class="electron-omega"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <bottom style="background: #1f1f1f"></bottom>

    <el-dialog title="选择学校" :visible.sync="schoolVisible">
      <div class="schoolSearch" style="display: none">
        <el-input
          placeholder="请输入内容"
          v-model="inputqucikSchool"
          class="input-with-select"
        >
          <el-select
            v-model="selectSchoolAddress"
            slot="prepend"
            placeholder="请选择学校区域"
            style="width: 139px"
          >
            <el-option label="区属" value="1"></el-option>
            <el-option label="市属" value="2"></el-option>
            <el-option label="兴庆区" value="3"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>

      <div class="schoolList">
        <a
          v-for="(item, index) in schoolData"
          :key="index"
          @click="chooseSchool(item.name)"
          >{{ item.name }}</a
        >
      </div>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="tips"
      width="339px"
      style="height: 370px"
      @closed="tipClose()"
    >
      <span style="text-algin: left">{{ tipWords }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tips = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import loginResigter from "../components/loginResigter.vue";
import school from "../components/register/school.vue";
import { schoolData } from "../storage/SchoolData";
import bottom from "../components/bottom";
import judgeFormat from "../utils/verification.js";
import { my_throttle } from "../utils/utils.js";
import { Loading } from "element-ui";

export default {
  name: "contest",
  components: { school, bottom },
  data() {
    return {
      very_word: "发送验证码",
      very_disabled: false,
      phoneNumber: 0,
      schoolVisible: false,
      schoolData,
      inputqucikSchool: "",
      selectSchoolAddress: "",
      smsButton: "发送验证码",
      form: {
        username: "",
        password: "",
        repassword: "",
        name: "",
        category: "",
        school: "",
        invitation:"",
        code: "",
      },
      options: [
        {
          value: "Scratch",
          label: "Scratch",
        },
        {
          value: "Python",
          label: "Python",
        },
        {
          value: "C++",
          label: "C++",
        },
      ],
      tipWords: "",
      tips: false,
      loginState: true,
      veryfy: null,
    };
  },
  methods: {
    login() {
      let form = Object.assign({}, this.form);
      if (!judgeFormat.phoneNumber(form.username)) {
        this.tipWords = "请核对您的手机号！";
        this.tips = true;
      } 
      // else if (!judgeFormat.passWord(form.password)) {
      //   this.tipWords = "请确定您的密码格式！";
      //   this.tips = true;
      // } 
      else {
        let loadingInstance = Loading.service({ fullscreen: true });
        this.$http
          .post("/user", {
            username: this.form.username,
            password: this.form.password,
            mode: "login",
          })
          .then((response) => {
            loadingInstance.close();
            console.log(response);
            this.tipWords = response.data.status;
            this.tips = true;

            this.$localStorage.set("login_status_username", this.form.username);
            this.$localStorage.set("login_status_token", this.form.password);
            this.$localStorage.set("steam_userInfo", response.data.res);
          })
          .catch((error) => {
            loadingInstance.close();
            console.log(error);
            this.tipWords = error.data.status;
            this.tips = true;
          });
      }
      console.log(this.form);
    },
    register() {
      // this.tipWords = "邀请码错误！请联系学校老师获取邀请码";
      // this.tips = true;

      let form = Object.assign({}, this.form);
      if (!judgeFormat.phoneNumber(form.username)) {
        this.tipWords = "请核对您的手机号！";
        this.tips = true;
      } else if (!judgeFormat.passWord(form.password)) {
        this.tipWords = "密码必须由字母、数字组成，密码需为8-20区分大小写！";
        this.tips = true;
      } else if (form.password != form.repassword) {
        this.tipWords = "两次密码输入不一致请核对后再试!";
        this.tips = true;
      } else if (form.school == "") {
        this.tipWords = "请选择学校!";
        this.tips = true;
      } else if (form.invitation != "steamleader") {
        this.tipWords = "邀请码错误！请联系学校老师获取邀请码!";
        this.tips = true;
      } else {
        let loadingInstance = Loading.service({ fullscreen: true });

        this.$http
          .post("/user", {
            ...form,
            mode: "register",
          })
          .then((response) => {
            loadingInstance.close();
            console.log(response);
            this.tipWords = response.data;
            this.tips = true;
          })
          .catch((error) => {
            loadingInstance.close();
            console.log(error);
            this.tipWords = error.data;
            this.tips = true;
          });
      }
      // console.log(this.form);
    },
    sendMessage() {
      if (this.veryfy != null) return;
      let form = Object.assign({}, this.form);

      if (!judgeFormat.phoneNumber(form.username)) {
        this.tipWords = "请核对您的手机号！";
        this.tips = true;
        return;
      } else {
        let loadingInstance = Loading.service({ fullscreen: true });

        this.$http
          .post("/sms", {
            cmd: "register",
            phone: form.username,
            cloud: false, //短信接口cloud false，云下，true云上用event
          })
          .then((response) => {
            loadingInstance.close();
            console.log(response);
            this.tipWords = String(response.data.msg);
            this.tips = true;
          })
          .catch((error) => {
            loadingInstance.close();
            console.log(error);
            this.tipWords = JSON.stringify(error.data);
            this.tips = true;
          });
      }
      let time = 10;

      this.veryfy = setInterval(() => {
        this.smsButton = `${time--}秒后重新发送`;
        if (time == 0) {
          this.smsButton = true;
          this.smsButton = "发送验证码";
          clearInterval(this.veryfy);
          this.veryfy = null;
        }
      }, 1000);
    },
    chooseSchool(school) {
      console.log(school);
      this.form.school = school;
      this.schoolVisible = false;
    },
    jump(name) {
      this.$store.commit("changeCategory", name);
      this.$router.push(`/${name}`);
    },
    tipClose() {
      if (this.tipWords == "注册成功！") this.loginState = true;
      if (this.tipWords == "登录成功！") this.$router.push("/");
    },
  },
  mounted() {
    let params = this.$route.query.id;
    console.log(params);
    if (params == "register") this.loginState = false;
  },
};
</script>

<style scoped>
@import "../assets/loginCss/global.css";
@import "../assets/loginCss/passport.css";
@import "../assets/loginCss/yuanzi.css";
.atom {
  margin: auto;
}
.schoolSearch {
  margin: 20px;
}
.schoolList {
  /* height: 399px;加了搜索框高度 */
  height: 428px;
  margin-top: 10px;
  /* 上面两条没加搜索框 */
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(auto-fill, 218px);
}
.schoolList a {
  margin: 8px 10px;
}
</style>

<style >
.el-dialog[title="选择学校"] {
  height: 588px;
  width: 699px;
}

.el-dialog__body {
  padding-right: 0px;
}
.el-select > .el-input {
  display: inline-block !important;
}
.el-select {
  display: block;
}

.el-select .el-input__inner {
  padding-right: 0px;
}
</style>