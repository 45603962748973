<template>
  <div :class="{ 'login-bottom': true, dark: dark }">
    <p>
      <a :class="{darkA:dark}" href="http://www.nxcode.net/" target="_blank"
        >蒸汽创客</a
      >
      |
      <a :class="{darkA:dark}" href="http://www.nxcode.net/learn" target="_blank"
        >关于我们</a
      >
      |
      <a
        :class="{darkA:dark}"
        href="http://www.nxcode.net/learn"
        target="_blank"
        >联系我们</a
      >
      |
      <a
        :class="{darkA:dark}"
        href="https://mp.weixin.qq.com/s/qhAWZRLAymQ0_QZduKwJnA"
        target="_blank"
        >官方微信公众号</a
      >
      |
      <a :class="{darkA:dark}" href="https://beian.miit.gov.cn/" target="_blank"
        >宁ICP备19001311号-1</a
      >
      <!-- |
      <a :class="{darkA:dark}" href="https://www.i3done.com/news/privacy-policy.html" target="_blank"
        >隐私保护声明</a
      > -->
    </p>
    <p>
      Copyright 2020 © 宁夏智途教育信息咨询有限公司
      <a :class="{darkA:dark}" href=""><b>steam教育</b></a
      ><a :class="{darkA:dark}" href=""><b>创客空间</b></a> All rights reserved.
    </p>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
    dark: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  computed: {
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-bottom {
  background: white;
  padding: 50px 0;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  position: relative;
  z-index: 51;
}

.login-bottom p {
  line-height: 190%;
}
.dark {
  background: #1f1f1f;
  color: #78cae5;
}
.darkA {
  color: #78cae5;
}
</style>
