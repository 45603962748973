export let schoolData = [
    { name: "宁夏六盘山高级中学", stage: "高中", area: "区属" },
    { name: "宁夏育才中学", stage: "高中", area: "区属" },
    { name: "银川市第一中学", stage: "高中", area: "区属" },
    { name: "宁夏长庆高级中学", stage: "高中", area: "区属" },
    { name: "宁夏长庆初级中学", stage: "初中", area: "区属" },
    { name: "宁夏长庆小学", stage: "小学", area: "区属" },
    { name: "宁夏大学附属中学", stage: "初高中", area: "区属" },
    { name: "银川第二十一小学", stage: "小学", area: "区属" },
    { name: "银川博文小学", stage: "小学", area: "市属民办" },
    { name: "银川西夏德胜小学", stage: "小学", area: "市属民办" },
    { name: "银川滨河新区小孔明学校", stage: "一体化", area: "市属民办" },
    { name: "宁夏岳麓高级中学", stage: "高中", area: "市属民办" },
    { name: "银川外国语实验学校", stage: "初高中", area: "市属民办" },
    { name: "银川英才学校", stage: "初中", area: "市属民办" },
    { name: "银川景博学校", stage: "一体化", area: "市属民办" },
    { name: "银川市实验小学", stage: "小学", area: "市属" },
    { name: "银川市第六中学", stage: "初高中", area: "市属" },
    { name: "银川市第九中学", stage: "初高中", area: "市属" },
    { name: "银川市唐徕回民中学高中校区", stage: "高中", area: "市属" },
    { name: "银川市唐徕回民中学西校区", stage: "初中", area: "市属" },
    { name: "银川市唐徕回民中学南校区", stage: "初中", area: "市属" },
    { name: "银川市唐徕回民中学宝湖校区", stage: "初中", area: "市属" },
    { name: "银川市第二十一小学湖畔分校", stage: "小学", area: "市属" },
    { name: "银川市第二中学", stage: "初高中", area: "市属" },
    { name: "银川市第二十五中学", stage: "初中", area: "市属" },
    { name: "银川市阅海小学", stage: "小学", area: "市属" },
    { name: "银川市永泰小学", stage: "小学", area: "市属" },
    { name: "银川市北塔中学", stage: "初高中", area: "市属" },
    { name: "银川市高级中学", stage: "高中", area: "市属" },
    { name: "银川市阅海第二中学", stage: "", area: "市属" },
    { name: "银川市阅海中学", stage: "初中", area: "市属" },
    { name: "银川市实验中学", stage: "高中", area: "市属" },
    { name: "银川市第二十四中学（初中部）", stage: "初中", area: "市属" },
    { name: "银川市第二十四中学（高中部）", stage: "高中", area: "市属" },
    { name: "银川市滨河景城第一小学", stage: "小学", area: "市属" },
    { name: "北京师范大学银川学校", stage: "一体化", area: "市属" },
    { name: "银川市滨河景城第一中学", stage: "初中", area: "市属" },
    { name: "银川市阅海第二小学", stage: "小学", area: "市属" },
    { name: "银川市阅海第三小学", stage: "小学", area: "市属" },
    { name: "银川市阅海第四小学", stage: "小学", area: "市属" },
    { name: "银川市西夏区实验小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第七小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第十小学北校区", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第九小学北校区", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区华西中学", stage: "中学", area: "西夏区" },
    { name: "银川市中关村小学", stage: "小学", area: "西夏区" },
    { name: "银川市第十八中学", stage: "中学", area: "西夏区" },
    { name: "银川市西夏区第二小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区芦花中学", stage: "九年制", area: "西夏区" },
    { name: "银川市西夏区第四小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第八小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第一小学", stage: "小学", area: "西夏区" },
    { name: "银川市第十六中学", stage: "中学", area: "西夏区" },
    { name: "银川市西夏区兴泾回民中学", stage: "中学", area: "西夏区" },
    { name: "银川市西夏区第十八小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区平吉堡小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区回民小学", stage: "小学", area: "西夏区" },
    { name: "银川市中关村中学", stage: "中学", area: "西夏区" },
    { name: "银川市西夏区中石油希望小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区芦花洲小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区实验小学北校区", stage: "小学", area: "西夏区" },
    { name: "西夏区镇北堡华西希望小学", stage: "九年制", area: "西夏区" },
    { name: "银川市西夏区兴泾镇回民二小", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第十小学南校区", stage: "小学", area: "西夏区" },
    { name: "银川市第八中学", stage: "中学", area: "西夏区" },
    { name: "银川市西夏区第三小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第十三小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区南梁小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第十七小学", stage: "小学", area: "西夏区" },
    { name: "银川市第十四中学", stage: "中学", area: "西夏区" },
    { name: "银川市西夏区第十一小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区兴泾镇回民一小", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区第九小学", stage: "小学", area: "西夏区" },
    { name: "银川市西夏区农科院小学", stage: "小学", area: "西夏区" },
    { name: "西夏区镇北堡镇梁渠稍小学", stage: "小学", area: "西夏区" },
    { name: "西夏区镇北堡镇奕龙希望小学", stage: "小学", area: "西夏区" },
    { name: "银川市金凤区第五小学", stage: "小学", area: "金凤区" },
    { name: "金凤区良田镇光彩小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区实验小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第二小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第十一小学", stage: "小学", area: "金凤区" },
    { name: "银川市实验小学金凤六街校区", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第一回民小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第十小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区良田镇回民中学", stage: "中学", area: "金凤区" },
    { name: "银川市金凤区第四回民小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第二十一小学", stage: "小学", area: "金凤区" },
    { name: "银川市第十三中学", stage: "中学", area: "金凤区" },
    { name: "银川市金凤区第二十二小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区丰登回民中学", stage: "九年制", area: "金凤区" },
    { name: "银川市金凤区奕龙回民希望小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区良田小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤润丰神华爱心小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区植物园回民小学", stage: "小学", area: "金凤区" },
    { name: "金凤区良田镇泾龙回民小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第八回民小学", stage: "小学", area: "金凤区" },
    { name: "金凤区良田镇曼新回民希望小学", stage: "小学", area: "金凤区" },
    { name: "银川市第二十六中学", stage: "中学", area: "金凤区" },
    { name: "银川市兴庆区回民二小观湖校区", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区回民二小北塔校区", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区回民二小海宝校区", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第十六小学", stage: "小学", area: "兴庆区" },
    { name: "银川市第十五中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区第三小学", stage: "小学", area: "兴庆区" },
    { name: "银川市第四中学", stage: "九年制", area: "兴庆区" },
    { name: "银川市回民中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区回民第一小学", stage: "小学", area: "兴庆区" },
    { name: "银川市第三中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区第四小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区唐徕回民小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第六小学", stage: "小学", area: "兴庆区" },
    { name: "银川市二十一小学北苑分校区", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区掌政中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区第七小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第十二小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第二十八小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区回民第三小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第二小学", stage: "小学", area: "兴庆区" },
    { name: "银川市第十中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区第二十三小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第十七小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第二十二小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第六小学湖滨分校", stage: "小学", area: "兴庆区" },
    { name: "银川市第五中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区回民中学", stage: "九年制", area: "兴庆区" },
    { name: "银川市兴庆区月牙湖回民中学", stage: "九年制", area: "兴庆区" },
    { name: "银川市兴庆区大塘中心小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区景岳小学", stage: "小学", area: "兴庆区" },
    { name: "兴庆区月牙湖回民第二中学", stage: "九年制", area: "兴庆区" },
    { name: "银川市第二十中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区回民实验小学", stage: "小学", area: "兴庆区" },
    { name: "银川市第十二中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区第二十五小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区回民三小掌政分校", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第二十七小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第二十九小学", stage: "小学", area: "兴庆区" },
    { name: "兴庆区回民第二小学新水桥小学", stage: "小学", area: "兴庆区" },
    { name: "兴庆区第六小学碱富桥校区", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区官湖小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区海军希望小学", stage: "小学", area: "兴庆区" },
    { name: "兴庆区回民实验小学新渠稍校区", stage: "小学", area: "兴庆区" },
    { name: "兴庆区唐徕回民小学通南分校", stage: "小学", area: "兴庆区" },
    { name: "贺兰县第七小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第一小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第一中学", stage: "初高中", area: "贺兰县" },
    { name: "贺兰县回民小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第二小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第四小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第四中学", stage: "中学", area: "贺兰县" },
    { name: "贺兰县第三小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第二中学", stage: "中学", area: "贺兰县" },
    { name: "贺兰县回民高级中学", stage: "中学", area: "贺兰县" },
    { name: "贺兰县第六小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县德胜实验小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县金贵镇潘旭小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县暖泉农场小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县铁西小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第三中学", stage: "九年制", area: "贺兰县" },
    { name: "贺兰县立岗镇兰光小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县立岗镇立岗小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县洪广镇欣荣小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县金贵镇银光小学", stage: "小学", area: "贺兰县" },
    { name: "宁夏二十一小奥莱小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第五小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县金贵镇新渠回民小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县金山小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县铁东小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县常信小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县五星小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县立岗镇乐民回民小学", stage: "小学", area: "贺兰县" },
    { name: "贺兰县第八小学", stage: "小学", area: "贺兰县" },
    { name: "灵武市第四小学", stage: "小学", area: "灵武市" },
    { name: "灵武市第七小学", stage: "小学", area: "灵武市" },
    { name: "永宁四中", stage: "中学", area: "永宁县" },
    { name: "永宁中学", stage: "初高中", area: "永宁县" },
    { name: "永宁回民高级中学", stage: "中学", area: "永宁县" },
    { name: "永宁二小北校区", stage: "小学", area: "永宁县" },
    { name: "永宁三小", stage: "小学", area: "永宁县" },
    { name: "永宁二小南校区", stage: "小学", area: "永宁县" },
    { name: "永宁三中", stage: "初中", area: "永宁县" },
    { name: "灵武职中", stage: "中专", area: "灵武市" },
    { name: "灵武市第一中学", stage: "初中", area: "灵武市" },
    { name: "灵武市第二中学", stage: "初中", area: "灵武市" },
    { name: "灵武市第三中学", stage: "初中", area: "灵武市" },
    { name: "灵武市第四中学", stage: "初中", area: "灵武市" },
    { name: "灵武市第五中学", stage: "初中", area: "灵武市" },
    { name: "灵武市回民中学", stage: "初中", area: "灵武市" },
    { name: "灵武英才学校", stage: "初高中", area: "灵武市" },
    { name: "新华桥学校", stage: "初中", area: "灵武市" },
    { name: "梧桐树学校", stage: "初中", area: "灵武市" },
    { name: "灵武市第一小学", stage: "小学", area: "灵武市" },
    { name: "灵武市第二小学", stage: "小学", area: "灵武市" },
    { name: "灵武市第三小学", stage: "小学", area: "灵武市" },
    { name: "灵武市第五小学", stage: "小学", area: "灵武市" },
    { name: "灵武市第六小学", stage: "小学", area: "灵武市" },
    { name: "灵武东塔学校", stage: "小学", area: "灵武市" },
    { name: "灵武东塔回小", stage: "小学", area: "灵武市" },
    { name: "灵武农场小学", stage: "小学", area: "灵武市" },
    { name: "银川市金凤区第六小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第三小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第七回民小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第十五小学", stage: "小学", area: "金凤区" },
    { name: "宁夏银川市二十一小亲水分校", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第十二小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区第十六小学", stage: "小学", area: "金凤区" },
    { name: "银川市金凤区外国语实验小学", stage: "小学", area: "金凤区" },
    { name: "银川市兴庆区第二十六小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第十九小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区第二十三小景福校区", stage: "小学", area: "兴庆区" },
    { name: "银川市第二十一中学", stage: "中学", area: "兴庆区" },
    { name: "银川市兴庆区第十小学", stage: "小学", area: "兴庆区" },
    { name: "银川市兴庆区蒙特梭利国际学校", stage: "小学", area: "兴庆区" },
    { name: "测试学校", stage: "小学", area: "兴庆区" }
]


let rawData = `区属	宁夏六盘山高级中学	高中
区属	宁夏育才中学	高中
区属	银川市第一中学	高中
区属	宁夏长庆高级中学	高中
区属	宁夏长庆初级中学	初中
区属	宁夏长庆小学	小学
区属	宁夏大学附属中学	初高中
区属	银川第二十一小学	小学
市属民办	银川博文小学	小学
市属民办	银川西夏德胜小学	小学
市属民办	银川滨河新区小孔明学校	一体化
市属民办	宁夏岳麓高级中学	高中
市属民办	银川外国语实验学校	初高中
市属民办	银川英才学校	初中
市属民办	银川景博学校	一体化
市属	银川市实验小学	小学
市属	银川市第六中学	初高中
市属	银川市第九中学	初高中
市属	银川市唐徕回民中学高中校区	高中
市属	银川市唐徕回民中学西校区	初中
市属	银川市唐徕回民中学南校区	初中
市属	银川市唐徕回民中学宝湖校区	初中
市属	银川市第二十一小学湖畔分校	小学
市属	银川市第二中学	初高中
市属	银川市第二十五中学	初中
市属	银川市阅海小学	小学
市属	银川市永泰小学	小学
市属	银川市北塔中学	初高中
市属	银川市高级中学	高中
市属	银川市阅海第二中学	
市属	银川市阅海中学	初中
市属	银川市实验中学	高中
市属	银川市第二十四中学（初中部）	初中
市属	银川市第二十四中学（高中部）	高中
市属	银川市滨河景城第一小学	小学
市属	北京师范大学银川学校	一体化
市属	银川市滨河景城第一中学	初中
市属	银川市阅海第二小学	小学
市属	银川市阅海第三小学	小学
市属	银川市阅海第四小学	小学
西夏区	银川市西夏区实验小学	小学
西夏区	银川市西夏区第七小学	小学
西夏区	银川市西夏区第十小学北校区	小学
西夏区	银川市西夏区第九小学北校区	小学
西夏区	银川市西夏区华西中学	中学
西夏区	银川市中关村小学	小学
西夏区	银川市第十八中学	中学
西夏区	银川市西夏区第二小学	小学
西夏区	银川市西夏区芦花中学	九年制
西夏区	银川市西夏区第四小学	小学
西夏区	银川市西夏区第八小学	小学
西夏区	银川市西夏区第一小学	小学
西夏区	银川市第十六中学	中学
西夏区	银川市西夏区兴泾回民中学	中学
西夏区	银川市西夏区第十八小学	小学
西夏区	银川市西夏区平吉堡小学	小学
西夏区	银川市西夏区回民小学	小学
西夏区	银川市中关村中学	中学
西夏区	银川市西夏区中石油希望小学	小学
西夏区	银川市西夏区芦花洲小学	小学
西夏区	银川市西夏区实验小学北校区	小学
西夏区	西夏区镇北堡华西希望小学	九年制
西夏区	银川市西夏区兴泾镇回民二小	小学
西夏区	银川市西夏区第十小学南校区	小学
西夏区	银川市第八中学	中学
西夏区	银川市西夏区第三小学	小学
西夏区	银川市西夏区第十三小学	小学
西夏区	银川市西夏区南梁小学	小学
西夏区	银川市西夏区第十七小学	小学
西夏区	银川市第十四中学	中学
西夏区	银川市西夏区第十一小学	小学
西夏区	银川市西夏区兴泾镇回民一小	小学
西夏区	银川市西夏区第九小学	小学
西夏区	银川市西夏区农科院小学	小学
西夏区	西夏区镇北堡镇梁渠稍小学	小学
西夏区	西夏区镇北堡镇奕龙希望小学	小学
金凤区	银川市金凤区第五小学	小学
金凤区	金凤区良田镇光彩小学	小学
金凤区	银川市金凤区实验小学	小学
金凤区	银川市金凤区第二小学	小学
金凤区	银川市金凤区第十一小学	小学
金凤区	银川市实验小学金凤六街校区	小学
金凤区	银川市金凤区第一回民小学	小学
金凤区	银川市金凤区第十小学	小学
金凤区	银川市金凤区良田镇回民中学	中学
金凤区	银川市金凤区第四回民小学	小学
金凤区	银川市金凤区第二十一小学	小学
金凤区	银川市第十三中学	中学
金凤区	银川市金凤区第二十二小学	小学
金凤区	银川市金凤区丰登回民中学	九年制
金凤区	银川市金凤区奕龙回民希望小学	小学
金凤区	银川市金凤区良田小学	小学
金凤区	银川市金凤润丰神华爱心小学	小学
金凤区	银川市金凤区植物园回民小学	小学
金凤区	金凤区良田镇泾龙回民小学	小学
金凤区	银川市金凤区第八回民小学	小学
金凤区	金凤区良田镇曼新回民希望小学	小学
金凤区	银川市第二十六中学	中学
兴庆区	银川市兴庆区回民二小观湖校区	小学
兴庆区	银川市兴庆区回民二小北塔校区	小学
兴庆区	银川市兴庆区回民二小海宝校区	小学
兴庆区	银川市兴庆区第十六小学	小学
兴庆区	银川市第十五中学	中学
兴庆区	银川市兴庆区第三小学	小学
兴庆区	银川市第四中学	九年制
兴庆区	银川市回民中学	中学
兴庆区	银川市兴庆区回民第一小学	小学
兴庆区	银川市第三中学	中学
兴庆区	银川市兴庆区第四小学	小学
兴庆区	银川市兴庆区唐徕回民小学	小学
兴庆区	银川市兴庆区第六小学	小学
兴庆区	银川市二十一小学北苑分校区	小学
兴庆区	银川市兴庆区掌政中学	中学
兴庆区	银川市兴庆区第七小学	小学
兴庆区	银川市兴庆区第十二小学	小学
兴庆区	银川市兴庆区第二十八小学	小学
兴庆区	银川市兴庆区回民第三小学	小学
兴庆区	银川市兴庆区第二小学	小学
兴庆区	银川市第十中学	中学
兴庆区	银川市兴庆区第二十三小学	小学
兴庆区	银川市兴庆区第十七小学	小学
兴庆区	银川市兴庆区第二十二小学	小学
兴庆区	银川市兴庆区第六小学湖滨分校	小学
兴庆区	银川市第五中学	中学
兴庆区	银川市兴庆区回民中学	九年制
兴庆区	银川市兴庆区月牙湖回民中学	九年制
兴庆区	银川市兴庆区大塘中心小学	小学
兴庆区	银川市兴庆区景岳小学	小学
兴庆区	兴庆区月牙湖回民第二中学	九年制
兴庆区	银川市第二十中学	中学
兴庆区	银川市兴庆区回民实验小学	小学
兴庆区	银川市第十二中学	中学
兴庆区	银川市兴庆区第二十五小学	小学
兴庆区	银川市兴庆区回民三小掌政分校	小学
兴庆区	银川市兴庆区第二十七小学	小学
兴庆区	银川市兴庆区第二十九小学	小学
兴庆区	兴庆区回民第二小学新水桥小学	小学
兴庆区	兴庆区第六小学碱富桥校区	小学
兴庆区	银川市兴庆区官湖小学	小学
兴庆区	银川市兴庆区海军希望小学	小学
兴庆区	兴庆区回民实验小学新渠稍校区	小学
兴庆区	兴庆区唐徕回民小学通南分校	小学
贺兰县	贺兰县第七小学	小学
贺兰县	贺兰县第一小学	小学
贺兰县	贺兰县第一中学	初高中
贺兰县	贺兰县回民小学	小学
贺兰县	贺兰县第二小学	小学
贺兰县	贺兰县第四小学	小学
贺兰县	贺兰县第四中学	中学
贺兰县	贺兰县第三小学	小学
贺兰县	贺兰县第二中学	中学
贺兰县	贺兰县回民高级中学	中学
贺兰县	贺兰县第六小学	小学
贺兰县	贺兰县德胜实验小学	小学
贺兰县	贺兰县金贵镇潘旭小学	小学
贺兰县	贺兰县暖泉农场小学	小学
贺兰县	贺兰县铁西小学	小学
贺兰县	贺兰县第三中学	九年制
贺兰县	贺兰县立岗镇兰光小学	小学
贺兰县	贺兰县立岗镇立岗小学	小学
贺兰县	贺兰县洪广镇欣荣小学	小学
贺兰县	贺兰县金贵镇银光小学	小学
贺兰县	宁夏二十一小奥莱小学	小学
贺兰县	贺兰县第五小学	小学
贺兰县	贺兰县金贵镇新渠回民小学	小学
贺兰县	贺兰县金山小学	小学
贺兰县	贺兰县铁东小学	小学
贺兰县	贺兰县常信小学	小学
贺兰县	贺兰县五星小学	小学
贺兰县	贺兰县立岗镇乐民回民小学	小学
贺兰县	贺兰县第八小学	小学
灵武市	灵武市第四小学	小学
灵武市	灵武市第七小学	小学
永宁县	永宁四中	中学
永宁县	永宁中学	初高中
永宁县	永宁回民高级中学	中学
永宁县	永宁二小北校区	小学
永宁县	永宁三小	小学
永宁县	永宁二小南校区	小学
永宁县	永宁三中	初中
灵武市	灵武职中	中专
灵武市	灵武市第一中学	初中
灵武市	灵武市第二中学	初中
灵武市	灵武市第三中学	初中
灵武市	灵武市第四中学	初中
灵武市	灵武市第五中学	初中
灵武市	灵武市回民中学	初中
灵武市	灵武英才学校	初高中
灵武市	新华桥学校	初中
灵武市	梧桐树学校	初中
灵武市	灵武市第一小学	小学
灵武市	灵武市第二小学	小学
灵武市	灵武市第三小学	小学
灵武市	灵武市第五小学	小学
灵武市	灵武市第六小学	小学
灵武市	灵武东塔学校	小学
灵武市	灵武东塔回小	小学
灵武市	灵武农场小学	小学
金凤区	银川市金凤区第六小学	小学
金凤区	银川市金凤区第三小学	小学
金凤区	银川市金凤区第七回民小学	小学
金凤区	银川市金凤区第十五小学	小学
金凤区	宁夏银川市二十一小亲水分校	小学
金凤区	银川市金凤区第十二小学	小学
金凤区	银川市金凤区第十六小学	小学
金凤区	银川市金凤区外国语实验小学	小学
兴庆区	银川市兴庆区第二十六小学	小学
兴庆区	银川市兴庆区第十九小学	小学
兴庆区	银川市兴庆区第二十三小景福校区	小学
兴庆区	银川市第二十一中学	中学
兴庆区	银川市兴庆区第十小学	小学
兴庆区	银川市兴庆区蒙特梭利国际学校	小学
`


// let linedata = rawData.split(`
// `)
// let data = []
// let index = 0
// for (let value of linedata) {
//     data.push(value.split('\t'))
//     data[index] = { name: data[index][1], stage: data[index][2], area: data[index][0] }
//     index++
// }
// console.log(data)